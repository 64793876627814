import React, { useEffect, useState } from "react";
import { setSimulateurObject } from "../../../actions/simulateur.action";
import { useDispatch } from "react-redux";
import { styles } from "../../../stylesheets/simulateurs.css";
import "../../../stylesheets/simulateursBis.css";
import TextSimulateurTitle from "../../../components/texts/text.simulateurTitle";
import TextfieldNumber from "../../../components/textfields/textfield.number";
import SelectDepartement from "../../../components/selects/select.departement";
import ButtonYesno from "../../../components/buttons/button.yesno";
import DmtgMain from "../../dmtg/DmtgMain";
import TextfieldRemarques from "../../../components/textfields/textfiel.remarques";
import { ToolTipPerso } from "../../../components/selects/tooltip_perso";
import { Typography } from "@mui/material";
import TextfieldAbattement from "../../../components/textfields/textfield.abattement";

// Déclarations de successions
// Id simulateur 52, A2-12
// simulateurs associés : dmtg succession

function Simulateur_52(props, simulateur, isParent, departements) {
  const dispatch = useDispatch();

  const currentIdSimu = 52;
  let currUser = JSON.parse(localStorage.getItem("user"));
  let currUserDep = 0;
  if (currUser.societe_zip.startsWith("97")) {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 3);
    currUserDep = parseInt(trimedZip);
  } else {
    let trimedZip;
    trimedZip = currUser.societe_zip.slice(0, 2);
    currUserDep = parseInt(trimedZip);
  }

  const [simulateurForDMTG, setSimulateurForDMTG] = useState({
    id: 55,
    name: "DMTG - Succession 1 à 9 personnes",
  });

  const [departement, setDepartement] = useState(0);
  //MONTANT A
  const [montant_brut, setBrut] = useState(0);
  //MONTANT B
  const [total_rapportable, setTotalRapportable] = useState(0);
  //MONTANT C
  const [montant_passif, setPassif] = useState(0);
  //MONTANT D
  const [montant_net, setNet] = useState(0);
  //MONTANT E
  const [montant_succession, setSuccession] = useState(0);
  const [DMTG, setDMTG] = useState(0);
  const [responseDMTG, setResponseDMTG] = useState(null);

  useEffect(() => {
    const newSuccession = (montant_brut || 0) - (montant_passif || 0) + (total_rapportable || 0);
    setSuccession(newSuccession);
    handleChange(setSuccession, newSuccession);
  }, [montant_brut, montant_passif, total_rapportable]);

  const handleChange = async (setter, value) => {
    if (setter === setDepartement) {
      setDepartement(parseInt(value));
    } else if (setter === setBrut) {
      setBrut(parseInt(value));
      setNet((parseInt(value) || 0) - (montant_passif || 0));
      setSuccession(parseInt(value) - (montant_passif || 0) + (total_rapportable || 0));
    } else if (setter === setTotalRapportable) {
      setTotalRapportable(parseInt(value));
      setSuccession((montant_brut || 0) - (montant_passif || 0) + (parseInt(value) || 0));
    } else if (setter === setPassif) {
      setPassif(parseInt(value));
      setNet((montant_brut || 0) - (parseInt(value) || 0));
      setSuccession((montant_brut || 0) - (parseInt(value) || 0) + (total_rapportable || 0));
    } else setter(value);
    setInRedux(setter, value);
  };

  const handleChangeRemarque = async (value) => {
    localStorage.setItem("remarque", value);
  };

  const texteActifBrut =
    "Pour le calcul de l’émolument, l’assiette a pour base l’actif brut total, en ce compris s’il y a communauté, participation ou société d’acquêts, les biens qui en dépendent (C. com., art. A. 444-63, Tableau 5, n° 8).";

  const lignesTexteActifBrut = texteActifBrut.split("\n");

  const texteDonationRapport =
    "S’il y a des donations antérieures, seules les donations rapportables au sens civil sont à inclure dans l’assiette de l’émolument proportionnel pour leur valeur rapportable au jour du décès, quelle que soit leur ancienneté.";

  const lignesTexteDonationRapport = texteDonationRapport.split("\n");

  const textePassif = "Indiquer le montant du passif au sens fiscal";

  const lignesTextePassif = textePassif.split("\n");

  function setInRedux(setter, value) {
    if (props.isParent) {
      let object = {
        simulateurid: props.simulateur.id,
        departementid: 0,
        inputs: {},
        DMTG: [],
      };

      if (setter === setDepartement) {
        Object.assign(object.inputs, {
          montant_succession: parseInt(montant_succession),
          montant_brut: parseInt(montant_brut),
          total_rapportable: parseInt(total_rapportable),
          montant_passif: parseInt(montant_passif),
          montant_net: parseInt(montant_net),
          DMTG: parseInt(DMTG),
        });
        object.DMTG = responseDMTG;
        object.departementid = parseInt(value);
      }

      if (setter === setSuccession) {
        Object.assign(object.inputs, {
          montant_succession: parseInt(value),
          montant_brut: parseInt(montant_brut),
          total_rapportable: parseInt(total_rapportable),
          montant_passif: parseInt(montant_passif),
          montant_net: parseInt(montant_net),
          DMTG: parseInt(DMTG),
        });
        object.DMTG = responseDMTG;
        object.departementid = parseInt(departement);
      }

      if (setter === setBrut) {
        Object.assign(object.inputs, {
          montant_succession: parseInt(montant_succession),
          montant_brut: parseInt(value),
          total_rapportable: parseInt(total_rapportable),
          montant_passif: parseInt(montant_passif),
          montant_net: parseInt(montant_net),
          DMTG: parseInt(DMTG),
        });
        object.DMTG = responseDMTG;
        object.departementid = parseInt(departement);
      }

      if (setter === setTotalRapportable) {
        Object.assign(object.inputs, {
          montant_succession: parseInt(montant_succession),
          montant_brut: parseInt(montant_brut),
          total_rapportable: parseInt(value),
          montant_passif: parseInt(montant_passif),
          montant_net: parseInt(montant_net),
          DMTG: parseInt(DMTG),
        });
        object.DMTG = responseDMTG;
        object.departementid = parseInt(departement);
      }

      if (setter === setPassif) {
        Object.assign(object.inputs, {
          montant_succession: parseInt(montant_succession),
          montant_brut: parseInt(montant_brut),
          total_rapportable: parseInt(total_rapportable),
          montant_passif: parseInt(value),
          montant_net: parseInt(montant_net),
          DMTG: parseInt(DMTG),
        });
        object.DMTG = responseDMTG;
        object.departementid = parseInt(departement);
      }

      if (setter === setNet) {
        Object.assign(object.inputs, {
          montant_succession: parseInt(montant_succession),
          montant_brut: parseInt(montant_brut),
          total_rapportable: parseInt(total_rapportable),
          montant_passif: parseInt(montant_passif),
          montant_net: parseInt(value),
          DMTG: parseInt(DMTG),
        });
        object.DMTG = responseDMTG;
        object.departementid = parseInt(departement);
      }

      if (setter === setDMTG) {
        if (value === 0) {
          setResponseDMTG([]);
          Object.assign(object.inputs, {
            montant_succession: parseInt(montant_succession),
            montant_brut: parseInt(montant_brut),
            total_rapportable: parseInt(total_rapportable),
            montant_passif: parseInt(montant_passif),
            montant_net: parseInt(montant_net),
            DMTG: parseInt(value),
          });
          object.DMTG = [];
          object.departementid = parseInt(departement);
        } else {
          Object.assign(object.inputs, {
            montant_succession: parseInt(montant_succession),
            montant_brut: parseInt(montant_brut),
            total_rapportable: parseInt(total_rapportable),
            montant_passif: parseInt(montant_passif),
            montant_net: parseInt(montant_net),
            DMTG: parseInt(value),
          });
          object.DMTG = responseDMTG;
          object.departementid = parseInt(departement);
        }
      }

      if (setter === setResponseDMTG) {
        Object.assign(object.inputs, {
          montant_succession: parseInt(montant_succession),
          montant_brut: parseInt(montant_brut),
          total_rapportable: parseInt(total_rapportable),
          montant_passif: parseInt(montant_passif),
          montant_net: parseInt(montant_net),
          DMTG: parseInt(DMTG),
          result: 1,
        });
        object.DMTG = value;
        object.departementid = parseInt(departement);
      }
      dispatch(setSimulateurObject(object));
    } else {
      // process child simu
    }
  }

  return (
    <div style={styles.container}>
      <div className="cardSimulateur">
        <TextSimulateurTitle title={props.simulateur.name} currentSimuId={props.simulateur.id} />

        <TextfieldRemarques handleChange={handleChangeRemarque} />

        <p style={styles.cardSubTitle}>Acte tarifé au titre du Tableau 5, n° 8 (Art A444-63)</p>

        {props?.isParent === true && props.departements !== "" ? (
          <div className="cardRow">
            <div style={styles.cardElemVerticalyCenter}>Département :</div>

            <SelectDepartement
              items={props.departements}
              nameField={"name"}
              defaultValue={currUserDep}
              optionValue={"id"}
              handleChange={handleChange}
              setter={setDepartement}
            />
          </div>
        ) : (
          <></>
        )}
        {/*MONTANT A*/}
        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Actif brut de succession :
            <ToolTipPerso
              title={
                <Typography style={{ fontSize: 14 }}>
                  {lignesTexteActifBrut.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {ligne}
                    </React.Fragment>
                  ))}
                </Typography>
              }
            />
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setBrut}
          />
        </div>
        {/*MONTANT B*/}
        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Montant total des donations rapportables, qu'elles aient plus ou moins de 15 ans :
            <ToolTipPerso
              title={
                <Typography style={{ fontSize: 14 }}>
                  {lignesTexteDonationRapport.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {ligne}
                    </React.Fragment>
                  ))}
                </Typography>
              }
            />
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setTotalRapportable}
          />
        </div>

        {/*MONTANT C*/}
        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>
            Passif de succession :
            <ToolTipPerso
              title={
                <Typography style={{ fontSize: 14 }}>
                  {lignesTextePassif.map((ligne, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {ligne}
                    </React.Fragment>
                  ))}
                </Typography>
              }
            />
          </div>
          <TextfieldNumber
            handleChange={handleChange}
            type={"number"}
            placeholder={"Indiquer le montant"}
            setter={setPassif}
          />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Actif net de succession</div>
          <TextfieldAbattement type={"number"} placeholder={montant_net.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €"} />
        </div>

        <div className="cardRow">
          <div style={styles.cardElemVerticalyCenter}>Souhaitez-vous faire le calcul des DMTG ?</div>
          <div>
            <ButtonYesno handleChange={handleChange} setter={setDMTG} fieldUndefined={false} defaultValue={0} />
          </div>
        </div>

        {DMTG ? (
          <DmtgMain
            callSinceSimu={true}
            simulateur={simulateurForDMTG}
            setter={setResponseDMTG}
            handleChange={handleChange}
            calculateDMTGState={props.calculateDMTGState}
            montant_brut={montant_brut}
            total_rapportable={total_rapportable}
            montant_passif={montant_passif}
            montant_net={montant_net}
            total_succession={montant_succession}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Simulateur_52;
